import React from 'react'
import Link from '../components/common/Link'

const NotFoundPage = () => (
  <article className="page-article" style={{ height: '75vh' }}>
    <h1 className="text-center">404 Page not found</h1>
    <p className="lead text-center">
      It seems like this page doesn't exist.
      <br />
      Check your address bar or go straight to the <Link to="/">home page</Link>
    </p>
  </article>
)

export default NotFoundPage
